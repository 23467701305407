$(document).ready(function($){

    $('#btn-toggle').click(function(e){
        e.preventDefault();
        $(".sec7").slideToggle(1000);
    });

    $("input[name=telephone]").bind("change keyup input", function() {
        var position = this.selectionStart - 1;
        var fixed = this.value.replace(/[^0-9]/g, "");
    
        if (this.value !== fixed) {
          this.value = fixed;
          this.selectionStart = position;
          this.selectionEnd = position;
        }
    });

    $('#carousel-servicios').owlCarousel({
        loop: true,
        startPosition: 1,
        center: true,
        items: 3,
        margin: 18,
        autoplay: true,
        dots:true,
        autoplayTimeout: 3000,
        smartSpeed: 450,
        responsive: {
            0: {
            items: 1,
            nav: true,
            },
            768: {
            items: 1,
            nav: true,
            },
            1000: {
            items: 3,
            nav: true,
            }
        }
    });

    $('#carousel-mision').owlCarousel({
        loop: true,
        startPosition: 1,
        center: true,
        items: 3,
        margin: 18,
        autoplay: false,
        dots:true,
        autoplayTimeout: 3000,
        smartSpeed: 450,
        responsive: {
            0: {
            items: 1,
            nav: true,
            },
            768: {
            items: 1,
            nav: true,
            },
            1000: {
            items: 3,
            nav: true,
            }
        }
    });

    $('#carousel-testimonios').owlCarousel({
        loop: true,
        startPosition: 1,
        center: true,
        items: 3,
        margin: 25,
        autoplay: false,
        dots:true,
        autoplayTimeout: 3000,
        smartSpeed: 450,
        responsive: {
            0: {
            items: 1,
            nav: true,
            },
            768: {
            items: 1,
            nav: true,
            },
            1000: {
            items: 3,
            nav: true,
            }
        }
    });

    $('#carousel-servicios-mobile').owlCarousel({
        loop: true,
        center: true,
        items: 3,
        margin: 25,
        autoplay: true,
        dots:true,
        autoplayTimeout: 3000,
        smartSpeed: 450,
        responsive: {
            0: {
            items: 1,
            nav: true,
            },
            768: {
            items: 1,
            nav: true,
            },
            1000: {
            items: 3,
            nav: true,
            }
        }
    });

    $('#carousel-clientes').owlCarousel({
        loop: true,
        startPosition: 1,
        center: true,
        items: 3,
        margin: 8,
        autoplay: true,
        dots:true,
        autoplayTimeout: 3000,
        smartSpeed: 450,
        responsive: {
            0: {
            items: 3,
            nav: true,
            },
            768: {
            items: 3,
            nav: true,
            },
            1000: {
            items: 6,
            nav: true,
            }
        }
    });

    $('.burgergg').on('click',function(){
        if($('.nav-mobile').hasClass('nav-mobile-active'))
        {
            $('.nav-mobile').removeClass('nav-mobile-active');
        }else
        {
            $('.nav-mobile').addClass('nav-mobile-active');
        }
        if($('.linea1').hasClass('toggle1')){$('.linea1').removeClass('toggle1');}else{$('.linea1').addClass('toggle1');}
        if($('.linea2').hasClass('toggle2')){$('.linea2').removeClass('toggle2');}else{$('.linea2').addClass('toggle2');}
        if($('.linea3').hasClass('toggle3')){$('.linea3').removeClass('toggle3');}else{$('.linea3').addClass('toggle3');}
    });

    $('.btn-cerrar').on('click',function(){
        if($('.nav-mobile').hasClass('nav-mobile-active'))
        {
            $('.nav-mobile').removeClass('nav-mobile-active');
        }else
        {
            $('.nav-mobile').addClass('nav-mobile-active');
        }
        if($('.linea1').hasClass('toggle1')){$('.linea1').removeClass('toggle1');}else{$('.linea1').addClass('toggle1');}
        if($('.linea2').hasClass('toggle2')){$('.linea2').removeClass('toggle2');}else{$('.linea2').addClass('toggle2');}
        if($('.linea3').hasClass('toggle3')){$('.linea3').removeClass('toggle3');}else{$('.linea3').addClass('toggle3');}
    });

})